<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <div class="personal">
        <div class="personal-title">Personal</div>
        <div class="personal-box">
          <div class="row">
            <div class="col-md-4">
              <div class="personal-item" v-if="user?.name">
                <input type="text" placeholder="Name" v-model="user.name" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="personal-item" v-if="user?.last">
                <input type="text" placeholder="Lastname" v-model="user.last" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="personal-item" v-if="user?.email">
                <input type="email" placeholder="Email" v-model="user.email" />
              </div>
            </div>
          </div>
          <div class="personal-bottom" v-if="user?.name">
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <button class="personal-btn">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4">
          <h2 class="text-white px-4 uppercase mb-2 text-2xl font-medium">КОНТАКТНАЯ ИНФОРМАЦИЯ</h2>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <div class="px-4">
              <div class="text-white/80 pl-6 pb-2">Имя</div>
              <div class="bg-gray-700 rounded-full flex items-center">
                <input
                  class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                  value="Luis"
                />
                <div class="pl-2 pr-3 text-white text-lg">$</div>
              </div>
            </div>
            <div class="px-4">
              <div class="text-white/80 pl-6 pb-2">Фамилия</div>
              <div class="bg-gray-700 rounded-full flex items-center">
                <input
                  class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                  value="Perera"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="py-4">
          <h2 class="text-white px-4 uppercase mb-2 text-2xl font-medium">КОНТАКТНАЯ ИНФОРМАЦИЯ</h2>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <div class="px-4">
              <div class="text-white/80 pl-6 pb-2">Адрес</div>
              <div class="bg-gray-700 rounded-full flex items-center">
                <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
                <div class="pl-2 pr-3 text-white text-lg">$</div>
              </div>
            </div>
            <div class="px-4">
              <div class="text-white/80 pl-6 pb-2">Страна</div>
              <div class="bg-gray-700 rounded-full flex items-center">
                <input
                  class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                  value="Испания"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-4 px-4">
          <div class="text-zinc-500 mr-auto">Аккаунт создан 11.11.2024 15:00:55</div>
          <button class="bg-gradient-to-r from-indigo-900 to-blue-600 h-14 px-4 rounded-full mx-4">
            <span class="text-white text-lg font-medium">Сохранить изменения</span>
          </button>
        </div>
        <div class="py-6">
          <h2 class="text-white px-4 uppercase mb-2 text-2xl font-medium">Фото профиля</h2>
          <div class="grid grid-cols-2 gap-5 mt-5">
            <article class="rounded-xl border-dashed border-4 p-3 text-center grid content-center">
              <div
                class="flex flex-col items-center justify-center my-3 relative border rounded-full border-white w-28 aspect-square mx-auto"
              >
                <div
                  class="text-4xl aspect-square absolute z-10 text-white top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-neutral-500/40"
                >
                  <span> &#x2B;</span>
                </div>
                <div class="rounded-full relative">
                  <img src="@/assets/new-images/address-book-solid.svg" class="w-12" />
                </div>
              </div>
              <div class="text-neutral-400 uppercase text-xl">
                Перетащите файл в кружок выше, чтобы загрузить его.
              </div>
            </article>
            <div class="bg-gray-800 p-4 rounded-xl border border-2 text-white/90">
              <div class="py-4 text-2xl">Не разрешается публиковать:</div>
              <div class="leading-8">
                <p>- Фотографии откровенно сексуального или порнографического характера</p>
                <p>- Изображения, направленные на разжигание ненависти или этнической/расовой агрессии</p>
                <p>- Фотографии лиц младше 18 лет</p>
                <p>- Фотографии, защищённые авторским правом третьих лиц</p>
                <p>- Изображения размером более 5 МБ и в формате, отличном от JPG, GIF или PNG</p>
              </div>
              <div class="pt-4 text-xl">Ваше лицо должно быть чётко видно на фото. Все фотографии и видео, загружаемые вами, должны соответствовать этим требованиям, в противном случае они могут быть удалены.</div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";
import { mapGetters } from "vuex";

export default {
  name: "TradeView",
  data() {
    return {
      user: null,
    };
  },
  components: {
    HeaderBlock,
    AsideBlock,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  mounted() {
    if (this.user == null) {
      this.user = this.getUser;
    }
  },
};
</script>

<style lang="scss" scoped>
.personal {
  width: 100%;
  height: 100%;
  &-title {
    text-align: center;
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &-item {
    input {
      width: 100%;
      height: 45px;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background: none;
      color: #fff;
    }
  }
  &-bottom {
    margin-top: 30px;
  }
  &-btn {
    width: 100%;
    line-height: 50px;
    border: 0;
    text-align: center;
    background: linear-gradient(265.56deg, #246cf9 -0.27%, #1e68f6 -0.26%, #0047d0 98.59%);
    height: 50px;
    border-radius: 100px;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    transition: 0.5s;
  }
}
</style>
