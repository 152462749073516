<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <h2 class="text-white px-6 uppercase mb-2 text-2xl text-center">Настройки</h2>
      <div class="grid grid-cols-2 gap-3">
        <div class="bg-gray-800 p-3 rounded-lg flex flex-col gap-4">
          <h2 class="text-white px-6 uppercase mb-2 text-2xl mb-3">Настройки</h2>
          <div class="px-2">
            <div class="text-white/80 pl-6 pb-2">Текущий пароль</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
              <div class="pl-2 pr-3 text-white text-lg">
                <img src="@/assets/new-images/eye-closed.svg" class="w-7" />
              </div>
            </div>
          </div>
          <div class="px-2">
            <div class="text-white/80 pl-6 pb-2">Новый пароль</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
              <div class="pl-2 pr-3 text-white text-lg">
                <img src="@/assets/new-images/eye-closed.svg" class="w-7" />
              </div>
            </div>
          </div>
          <div class="px-2">
            <div class="text-white/80 pl-6 pb-2">Подтвердите пароль</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
              <div class="pl-2 pr-3 text-white text-lg">
                <img src="@/assets/new-images/eye-closed.svg" class="w-7" />
              </div>
            </div>
          </div>
          <div class="mt-3 grid">
            <button class="bg-gradient-to-r from-indigo-900 to-blue-600 h-14 px-4 rounded-full mx-4">
              <span class="text-white text-lg font-medium">Изменить пароль</span>
            </button>
          </div>
        </div>
        <div class="bg-gray-800 p-3 rounded-lg grid gap-3">
          <h2 class="text-white px-6 uppercase mb-2 text-2xl mb-3">Активировать защиту 2fa</h2>
          <div class="px-2">
            <div class="text-white/80 pl-6 pb-2">Код активации для Google Authenticator</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input
                class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                value="!@C#LK!@M#LKCC!K@#C@#LCL"
              />
            </div>
          </div>
          <div class="px-2">
            <div class="text-white/80 pl-6 pb-2">
              Activate Authenticator and enter a generated code in field below
            </div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input
                class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                value="!@C#LK!@M#LKCC!K@#C@#LCL"
              />
            </div>
          </div>
          <div class="px-2">
            <div>QR Code</div>
            <img src="@/assets/images/qr-code.png" class="w-52 mt-2" />
          </div>
          <div class="mt-3 grid">
            <button class="bg-gradient-to-r from-indigo-900 to-blue-600 h-14 px-4 rounded-full mx-4">
              <span class="text-white text-lg font-medium">Активировать защиту 2fa</span>
            </button>
          </div>
        </div>
      </div>
      <div class="bg-gray-800 p-3 rounded-lg mt-4">
        <h2 class="text-white px-6 uppercase mb-2 text-2xl mb-3">Dashboard currency</h2>
        <div class="flex items-center gap-3">
          <button class="bg-slate-600 text-white px-4 h-14 rounded-full flex items-center border-4 border-sky-500">
            <div class="lang-flag lang-flag-en scale-125"></div>
            <div class="ml-5 text-xl">GBP</div>
          </button>
          <button class="bg-slate-600 text-white px-4 h-14 rounded-full flex items-center">
            <div class="lang-flag lang-flag-pl scale-125"></div>
            <div class="ml-5 text-xl">PLN</div>
          </button>
        </div>
      </div>
      <div class="bg-gray-800 p-3 rounded-lg mt-4">
        <h2 class="text-white px-6 uppercase mb-2 text-2xl mb-3">Language</h2>
        <div class="flex items-center gap-3">
          <button class="bg-slate-600 text-white px-4 h-14 rounded-full flex items-center border-4 border-sky-500">
            <div class="lang-flag lang-flag-en scale-125"></div>
            <div class="ml-5 text-xl">English</div>
          </button>
          <button class="bg-slate-600 text-white px-4 h-14 rounded-full flex items-center">
            <div class="lang-flag lang-flag-pl scale-125"></div>
            <div class="ml-5 text-xl">Polski</div>
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";

export default {
  name: "SettingsVue",
  data() {
    return {};
  },
  components: {
    HeaderBlock,
    AsideBlock,
  },
  computed: {},
  mounted() {},
};
</script>
