<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <div class="py-4 text-center">
        <h2 class="text-white px-12 uppercase mb-2 text-3xl">Счета управления</h2>
      </div>
      <div class="py-4">
        <h2 class="text-white px-6 uppercase mb-2 text-2xl">Перевод средств между вашими счетами</h2>
        <div class="grid grid-cols-2 gap-4">
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Со счета</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input
                class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                value="Счет CFD 1776450 ($0.00)"
              />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">На счет</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input
                class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1"
                value="Счет CFD 1776450 ($0.00)"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-3 items-end">
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Сумма</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <button class="bg-gradient-to-r from-indigo-900 to-blue-600 h-14 px-4 rounded-full w-full">
              <span class="text-white text-lg font-medium">Совершить перевод</span>
            </button>
          </div>
        </div>
      </div>
      <div class="main-deposits">
        <div class="main-deposits-title">Ваши счета</div>
        <div class="table-responsive">
          <table class="main-cabinet !bg-gray-800">
            <thead>
              <tr class="fs">
                <th class="nomobile fs">Счет</th>
                <th class="fs">Валюта</th>
                <th class="fs">Баланс</th>
                <th class="fs">Кредит</th>
                <th class="fs"></th>
              </tr>
            </thead>
            <tbody class="bg-slate-900">
              <tr class="text-white">
                <td class="fs p-1">CFD 1776450</td>
                <td class="fs p-1">USD</td>
                <td class="fs p-1">$0.00</td>
                <td class="fs p-1">$0.00</td>
                <td class="fs p-1">
                  <button class="border-2 border-blue-600 w-4/5 rounded-full h-14 px-4">Активный</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
