<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <div class="py-4">
        <h2 class="text-white px-12 uppercase mb-2 text-3xl">Запросите новый вывод средств</h2>
        <div class="grid grid-cols-2 gap-4">
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Сумма</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
              <div class="pl-2 pr-3 text-white text-lg">$</div>
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Детали вывода</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
        </div>
      </div>
      <div class="py-4">
        <h2 class="text-white px-12 uppercase mb-2 text-3xl">Детали вывода</h2>
        <div class="grid grid-cols-2 gap-4">
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Номер счета</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
              <div class="pl-2 pr-3 text-white text-lg">$</div>
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Имя владельца счета</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">IBAN (Международный номер банковского счета)</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">SWIFT-код банка</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Адрес получателя</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Маршрутный номер</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Название банка получателя</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Адрес банка</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Банковский код</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
          <div class="px-4">
            <div class="text-white/80 pl-6 pb-2">Описание</div>
            <div class="bg-gray-700 rounded-full flex items-center">
              <input class="bg-gray-700 px-3 h-14 text-white rounded-full outline-none flex-1" />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button class="bg-gradient-to-r from-indigo-900 to-blue-600 h-14 px-4 rounded-full mx-4">
            <span class="text-white text-lg font-medium">Запросить вывод</span>
          </button>
        </div>
      </div>
      <div class="main-deposits">
        <div class="main-deposits-title">{{ t("home.last") }}</div>
        <div class="table-responsive">
          <table class="main-cabinet">
            <thead>
              <tr class="fs">
                <th class="nomobile fs">{{ t("home.table1") }}</th>
                <th class="fs">{{ t("home.table2") }}</th>
                <th class="fs">{{ t("home.table3") }}</th>
                <th class="fs">{{ t("home.table4") }}</th>
                <th class="fs">{{ t("home.table5") }}</th>
              </tr>
            </thead>
            <tbody class="bg-slate-900"></tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
