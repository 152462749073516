<template>
  <HeaderBlock />
  <div class="box">
    <AsideBlock />
    <main class="main">
      <h2 class="text-white px-4 uppercase mb-2 text-2xl font-medium text-center">Сбережения</h2>
      <div class="flex gap-5 mt-10">
        <div class="flex-[40%] flex-grow-0">
          <h2 class="text-white/85 px-4 uppercase mb-2 text-2xl font-medium">Подробная информация</h2>
          <div class="grid gap-3">
            <div class="border-2 border-white/20 rounded-full h-14 flex items-center px-4">
              <div class="flex items-center">
                <img src="@/assets/new-images/tether-usdt.svg" class="w-6 h-6 mr-4 rounded-full" />
                <span class="text-white text-xl font-thin">USDT</span>
              </div>
              <div class="ml-auto flex items-center">
                <span class="text-white font-thin text-xl">15.0 - 55.0 %.</span>
                <img src="@/assets/new-images/chevron-down-solid.svg" class="w-4 ml-2" />
              </div>
            </div>
            <div class="border-2 border-white/20 rounded-full h-14 flex items-center px-4">
              <div class="flex items-center">
                <img src="@/assets/new-images/usa-icon.png" class="w-6 h-6 mr-4 rounded-full" />
                <span class="text-white text-xl font-thin">USDT</span>
              </div>
              <div class="ml-auto flex items-center">
                <span class="text-white font-thin text-xl">15.0 - 55.0 %.</span>
                <img src="@/assets/new-images/chevron-down-solid.svg" class="w-4 ml-2" />
              </div>
            </div>
            <div class="border-2 border-white/20 rounded-full h-14 flex items-center px-4">
              <div class="flex items-center">
                <img src="@/assets/new-images/flag-uk.svg" class="w-6 h-6 mr-4 rounded-full" />
                <span class="text-white text-xl font-thin">USDT</span>
              </div>
              <div class="ml-auto flex items-center">
                <span class="text-white font-thin text-xl">15.0 - 55.0 %.</span>
                <img src="@/assets/new-images/chevron-down-solid.svg" class="w-4 ml-2" />
              </div>
            </div>
            <div class="border-2 border-white/20 rounded-full h-14 flex items-center px-4">
              <div class="flex items-center">
                <img src="@/assets/new-images/bitcoin-btc-logo.svg" class="w-6 h-6 mr-4 rounded-full" />
                <span class="text-white text-xl font-thin">USDT</span>
              </div>
              <div class="ml-auto flex items-center">
                <span class="text-white font-thin text-xl">15.0 - 55.0 %.</span>
                <img src="@/assets/new-images/chevron-down-solid.svg" class="w-4 ml-2" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex-auto mt-12">
          <div class="bg-gray-800 min-h-60 rounded-2xl flex items-center justify-center">
            <div class="text-white/80">Выберите валюту и период</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderBlock from "@/components/HeaderBlock.vue";
import AsideBlock from "@/components/AsideBlock.vue";

export default {
  name: "SavingsView",
  data() {
    return {};
  },
  components: {
    HeaderBlock,
    AsideBlock,
  },
  computed: {},
  mounted() {},
};
</script>
